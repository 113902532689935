.backgroundBanner {
    width: 107%;
    text-align: center;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
}

.banner {
    background-color: #e5f7fbf8;
    padding: 20px 10px;
    text-align: center;
}

.content {
    max-width: 800px;
    margin: 0 auto;
}

.title {
    font-size: 2rem;
    color: #00796b;
    margin: 0 0 10px;
}

.description {
    font-size: 1rem;
    color: #004d40;
    margin: 0;
}