.footer {
  background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, #d2e1f5 100%);
  color: #666;
  padding: 10px 40px;
}

.footer-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  font-size: 15px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.footer-logo {
  flex: 1;
  text-align: center;
  margin-bottom: 40px;
}

.copyright-text {
  font-size: 10px;
}
.footer-logo .logo {
  max-width: 40px; 
}

.footer-contact {
  flex: 1;
  margin-bottom: 20px;
  font-size: 15px;
}

.footer-contact h4 {
  margin-bottom: 10px;
}

.footer-contact p {
  margin: 5px 0;
  font-size: 15px;
}

.footer-contact a {
  color: #666;
  text-decoration: none;
  font-size: 15px;
}

.footer-contact a:hover {
  color: #2e2e2e;
}

.footer-social {
  flex: 1;
  text-align: center;
  margin-bottom: 20px;
}

.footer-social h4 {
  margin-bottom: 10px;
  color: #666;
}

.social-icons {
  display: flex;
  justify-content: center;
  gap: 40px; /* Increase spacing to balance fewer icons */
}

.social-icons a {
  font-size: 1.5rem;
  color: #666;
  text-decoration: none;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: #2e2e2e;
  
}

.divider-footer {
  margin-top: 20px;
  text-align: center;
}