.order-banner-container {
    text-align: center;
    padding: 20px;
    background-color: #f9f9f9;
    margin-bottom: 50px;
}

.order-banner-title {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
}

.order-steps {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 800px;
    margin:auto;
}

.order-step {
    max-width: 300px;
    text-align: center;
    background: white;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.step-icon {
    font-size: 3em;
    margin-bottom: 10px;
    color: #2c5d86;
}

.step-title {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #285479;
}

.step-description {
    font-size: 1em;
    color: #666;
}