.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem;
  box-sizing: border-box;
  position: relative;
}

.wavy-line-container {
  position: absolute;
  top: 80px;
  left: 0;
  width: 100%;
  z-index: -1;
  pointer-events: none;
}

.home-header {
  padding: 2rem;
}

.class-title {
  display: flex;
  text-align:center;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

h1 {
  font-size: 4rem;
  margin-bottom: 1rem;
  color: #333;
  position: relative;
  z-index: 1; 
}

p {
  font-size: 1rem;
  color: #666;
}

.subtitle-text {
  color: #007bff;
  font-weight: bold;
  max-width: 90%;
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}

.heroimage {
  width: 400px;
  height: auto;
  margin-left: auto;
  margin-left: right;
}


.boximage {
  width: 200px;
  height: auto;
  margin-left: auto;
  margin-left: right;
  margin-bottom: 20px;
  border-radius: 5px;
}

.cta-button {
  padding: 0.8rem 0.8rem;
  font-size: 1.25rem;
  font-weight: bold;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.cta-button:hover {
  background-color: #0056b3;
}
.aligned {
  margin: auto;
  text-align: left;
}

.howitworks {
  display: flex;
  font-size: 2rem;
  margin-top: 50px;
  margin-bottom: 30px;
  font-weight: bold;
  max-width: 800px;
  padding: 2rem;
}

.boximage-how-it-works {
  display: flex;
  width: auto;
  height: auto;
  max-width: 250px;
  min-width: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.connectors {
  position: relative;
  left: 10px;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
}

.explanation-text {
  margin-top: 40px;
  max-width: 800px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  min-height: 150px;
}

.icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  max-width: 800px;
  gap: 10px;
}

.icon-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  min-width: 0;
  border: 1px solid #cccccc60;
  padding: 20px;
  box-sizing: border-box;
  max-width: 150px; 
  border-radius: 15px;
}

.icon-home {
  font-size: 4rem;
  color: rgb(129, 129, 129);
  margin-bottom: 10px;
}

.icon-item:hover {
  color: black;
  cursor: pointer;
}

.text-icon,
.icon-item.hovered .icon-home,
.icon-item.clicked .icon-home {
  color: black;
  transition: color 200ms;
}

.icon-item.hovered .pen-icon,
.icon-item.clicked .pen-icon {
  color: rgb(181, 34, 34);
  transition: background-color 0.3s;
  transition: color 200ms;
}

.icon-item.hovered .upload-icon,
.icon-item.clicked .upload-icon {
  color: rgb(27, 121, 27);
  transition: background-color 0.3s;
  transition: color 200ms;
}

.icon-item.hovered .book-icon,
.icon-item.clicked .book-icon {
  color: rgb(36, 36, 169);
  transition: background-color 0.3s;
  transition: color 200ms;
}

.text-icon {
  font-family: 'Lato', sans-serif;
  color: black;
  transition: color 200ms;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  font-size: 1rem;
}

.icon-item.hovered .text-icon,
.icon-item.clicked .text-icon {
  color: black;
}

.email-signup {
  position: relative;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.email-signup input {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 300px;
  margin-bottom: 10px;
}

.email-signup button {
  padding: 10px 20px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
}

.email-signup button:hover {
  background-color: #0056b3;
}

.message-container {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

.message-container p {
  margin: 5px 0;
  padding: 8px;
  border-radius: 5px;
  font-size: 0.9rem;
  color: white;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

/* Specific message background colors */
.thank-you-message {
  background-color: #4caf50;
  opacity: 0;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.invalid-email {
  background-color: #f44336;
  opacity: 0;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}

.already-joined {
  background-color: #ffa726;
  opacity: 0;
  transition: opacity 1s ease-in-out, transform 1s ease-in-out;
}
.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

.divider-footer-home {
  opacity: .1;
}

.bean {
  width: 350px; /* Width of the bean */
  height: 200px; /* Height of the bean */
  background-color: #133f8b96; /* Brown color for the bean */
  border-radius: 50% 40% 60% 50% / 50% 60% 40% 50%;
  position: relative;
  rotate: 200deg;
  left: -450px;
  top: 800px;
}

.bean1 {
  width: 350px; /* Width of the bean */
  height: 200px; /* Height of the bean */
  background-color: #133f8b96; /* Brown color for the bean */
  border-radius: 50% 40% 60% 50% / 50% 60% 40% 50%;
  position: relative;
  rotate: 200deg;
  right: 0;
  top: 800px;
}

@media only screen and (max-width: 600px) {
  .wavy-line-container {
    top: 100px;
  }


.text-icon {
  font-size: .8rem;
  }
}
