.flex-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 20px; /* Add spacing between the image and text */
  margin-top: 20px;
  margin-bottom: 40px;
  width: 100%;
}

.deliveryimage {
  max-width: 200px;
  height: auto;
  border-radius: 20px;
}

.shipping-text {
  font-size: 26px;
  font-weight: bold;
  color: #285479;
  text-align: left;
}