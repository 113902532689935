.privacy-policy {
    font-family: 'Arial', sans-serif;
    color: #333;
    line-height: 1.6;
    margin: 20px;
    padding: 20px;
    border-radius: 8px;
  }
  
  .privacy-title {
    text-align: center;
    font-size: 2em;
    margin-bottom: 10px;
    color: #2c3e50;
  }
  
  .last-updated {
    text-align: center;
    font-size: 0.9em;
    margin-bottom: 20px;
    color: #7f8c8d;
  }
  
  .section-title {
    font-size: 1.5em;
    margin-top: 20px;
    margin-bottom: 10px;
    color: #2980b9;
  }
  
  section {
    text-align: left;
  }
  
  p, ul, li {
    margin: 10px 0;
  }
  
  ul {
    padding-left: 20px;
  }
  
  li {
    text-decoration: none;
  }
  
  a {
    color: #2980b9;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }