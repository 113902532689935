.simple-image-uploader {
  max-width: 500px;
  height:auto;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 60px;
}

.simple-image-uploader:hover {
  cursor: pointer;
}

.dropzone {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
  border-radius: 10px;
  transition: border-color 0.3s, background-color 0.3s;
  text-align: center;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  background-color: #0057d1;
}

.dropzone p {
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1rem;
  width: 100%;
}

.dropzone:hover p {
  color: black;
}

.dropzone input {
  position: absolute;
  height: 100%;
  opacity: 0;
  top: 0;
  left: 0;
}

.dropzone.accept {
  border-color: #4caf50;
  background-color: rgba(76, 175, 80, 0.1); /* Light green tint */
  color: black;
}

.dropzone.reject {
  border-color: #f44336;
  background-color: rgba(244, 67, 54, 0.05); /* Faint red tint */
  color: black;
}

.dropzone:hover {
  color: black;
  border-color: #ebebeb;
  background-color: rgb(253, 253, 253);
  animation: glow 1.5s infinite alternate;
}

.hover-icons {
  position: relative;
  bottom: 10px;
  display: flex;
  gap: 70px;
  opacity: 0;
  transform: translateY(20px);
  animation: pop-icons .3s ease-out;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.hover-icons.loading {
  display: none;
}

/* Individual Icon Styling (Optional) */
.hover-icon {
  color: #007bff; /* Default color */
  font-size: 1.5rem;
  gap: 70px;
}

.image-icon {
  transform: rotate(-40deg);
}

.reg-image-icon {
  transform: rotate(40deg);
}

.dropzone:hover .image-icon {
  animation: spin-counterclockwise .3s ease-out;
}

.dropzone:hover .reg-image-icon {
  animation: spin-clockwise .3s ease-out ;
}

@keyframes spin-clockwise {
  from {
      transform: rotate(40deg);
      scale: 0;
  }
  to {
      transform: rotate(400deg);
      scale: 1;
  }
}

/* Rotate counterclockwise from -40deg to -400deg */
@keyframes spin-counterclockwise {
  from {
      transform: rotate(-40deg);
      scale: 0;
  }
  to {
      transform: rotate(-400deg);
      scale: 1;
  }
}


/* Show Hover Icons on Dropzone Hover */
.dropzone:hover .hover-icons {
  opacity: 1;
  transform: translateY(0);
  gap: 70px;
}

.glowing {
  animation: glow 1.5s infinite alternate;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 10px rgba(33, 150, 243, 0.5),
      0 0 20px rgba(33, 150, 243, 0.4),
      0 0 30px rgba(33, 150, 243, 0.3);
  }

  100% {
    box-shadow: 0 0 20px rgba(33, 150, 243, 0.7),
      0 0 40px rgba(33, 150, 243, 0.5),
      0 0 60px rgba(33, 150, 243, 0.4);
  }
}

.dropzone.loading {
  background-color: #ffffff;
  cursor: progress;
  color: rgb(46, 46, 46);
  pointer-events: none;
  animation: glowLoad 1.5s ease-in-out infinite;
  position: relative;
  overflow: hidden;

  /* Flexbox adjustments for alignment */
  display: flex;
  flex-direction: column; /* Stack items vertically */
  align-items: center; /* Center horizontally */
  justify-content: center; /* Center vertically */
  text-align: center;
  height: 100%; /* Ensure it fills the container */
}

/* Glow animation with color change */
@keyframes glowLoad {
  0% {
      box-shadow: 0 0 10px #1abc9c, 0 0 20px #3498db, 0 0 30px #9b59b6;
  }
  50% {
      box-shadow: 0 0 20px #1abc9c, 0 0 30px #3498db, 0 0 40px #9b59b6;
  }
  100% {
      box-shadow: 0 0 10px #1abc9c, 0 0 20px #3498db, 0 0 30px #9b59b6;
  }
}

.particle {
  position: absolute;
  border-radius: 50%;
  width: 8px;
  height: 8px;
  background-color: rgba(33, 150, 243, 0.7);
  animation: particleMove 3s linear infinite, particleFade 3s ease-in-out infinite;
  pointer-events: none;
}

@keyframes particleMove {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(calc(50px - 100px * var(--x)), calc(50px - 100px * var(--y)));
  }
}

@keyframes particleFade {
  0%, 100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.dropzone.loading .particle-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -4;
}

.dropzone.loading:hover {
  border-color: initial;
  background-color: initial;
}

.dropzone.loading .media-icon {
  display: none;
}

.story-content {
  display: flex;
  flex-direction: column;
  /* Stack the image and text vertically */
  align-items: center;
  /* Center image and text */
  max-width: 800px;
  margin-top: 40px;
  max-width: 100%;
}

.story-preview {
  display: flex;
  flex-direction: column;
  /* Stack elements vertically */
  align-items: center;
  /* Center contents horizontally */
  text-align: center;
  margin-top: 40px;
  width: 100%;
  max-width: 800px;
}


.text-uploader {
  color: rgb(51, 51, 51);
  display: flex;
  flex-direction: column;
  /* Stack items vertically */
  align-items: center;
  /* Center items horizontally */
  justify-content: center;
  /* Center items vertically within container */
  text-align: center;
  gap: 10px;
  font-weight: bold;
  /* Optional: Add space between text and loader */
}

.uploaded-image {
  max-width: 50%;
  /* Limits image width */
  height: auto;
  border-radius: 10px;
}

.story-text {
  flex: 1;
  /* Allows text to take remaining space */
  font-size: 1rem;
  color: #666;
  margin-top: 40px;
  text-align: center;
  max-width: 800px;
}

.intro-story {
  font-weight: bolder;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 10px;
}

.uploader-container {
  max-width: 500px;
  position: relative;
  align-items: center;
  justify-content: center;
}

.funnel {
  position: absolute;
  top: -130px;
  margin-right: 300px;
  width: 100%;
  max-width: 700px;
  /* Adjust this size as needed */
  height: auto;
  z-index: -1;
  /* Ensure it is behind the dropzone */
  pointer-events: none;
  transform: rotateX(180deg);
}

.funnel2 {
  position: absolute;
  top: -70px;
  margin-right: 700px;
  width: 100%;
  max-width: 700px;
  /* Adjust this size as needed */
  height: auto;
  z-index: -1;
  /* Ensure it is behind the dropzone */
  pointer-events: none;
  transform: rotateZ(-170deg);
}

.funnel3 {
  position: absolute;
  top: -160px;
  margin-right: 700px;
  width: 100%;
  max-width: 700px;
  /* Adjust this size as needed */
  height: auto;
  z-index: -1;
  /* Ensure it is behind the dropzone */
  pointer-events: none;
  transform: rotateZ(-140deg);
}

.media-icon {
  width: 40px;
  height: auto;
  font-size: 2rem;
  color: white;
}

.dropzone:hover .media-icon {
  color: #007bff;
  animation: scaling .3s ease-in-out;
}

@keyframes scaling {
  0% {
    scale: 1;
  }
  50% {
    scale: 1.2;
  }

  100% {
    scale: 1;
  }
}

.divider-cta {
  opacity: .1;
  margin-top: 40px;
  margin-bottom: 40px;
}

.cta-sub {
  font-size: 1.5rem;
}

.cta-img {
  width: 80px;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
