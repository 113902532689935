@keyframes slideUpFadeIn {
    from {
        transform: translateY(20px);
        opacity: 0;
    }

    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes slideDownFadeOut {
    from {
        transform: translateY(0);
        opacity: 1;
    }

    to {
        transform: translateY(20px);
        opacity: 0;
    }
}

.consent-banner.slide-out {
    animation: slideDownFadeOut 0.5s ease forwards;
}

.consent-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ffffff;
    color: #333;
    padding: 18px;
    box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.2);
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 99999999;
    opacity: 0;
    transform: translateY(20px);
    border-radius: 20px 20px 0 0;
    max-width: 400px;
}

.consent-banner.slide-in {
    animation: slideUpFadeIn 0.5s ease forwards;
}

.consent-banner p {
    margin: 0 0 10px;
    text-align: center;
    max-width: 600px;
    font-size: 1rem;
}

.consent-banner a {
    color: #0066cc;
    text-decoration: underline;
    font-size: inherit;
}

.consent-buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
}

.spacing-policy {
    margin-top: 10px;
}

.accept-btn,
.decline-btn {
    background: #0066cc;
    color: #fff;
    border: none;
    border-radius: 15px;
    padding: 8px 15px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
}

.accept-btn {
    background: #0066cc;
}

.decline-btn {
    background: #777;
}

.accept-btn:hover {
    background: #005bb5;
}

.decline-btn:hover {
    background: #555;
}

.cookie-image {
    width: 100px;
    height: auto;
}

.consent-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

@media (min-width: 768px) {
    .consent-buttons {
        justify-content: flex-start;
    }

    .consent-banner {
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .consent-content {
        margin-left: 20px;
        margin-top: 0;
    }
}