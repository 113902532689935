.not-found-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    background-color: #f9fafb;
    font-family: 'Helvetica Neue', Arial, sans-serif;
    color: #333;
  }
  
  .not-found-title {
    font-size: 6rem;
    font-weight: bold;
    color: #4c6ef5;
    margin-bottom: 1rem;
  }
  
  .not-found-message {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  
  .not-found-subtext {
    font-size: 1rem;
    color: #666;
    margin-bottom: 2rem;
  }
  
  .not-found-link {
    padding: 12px 24px;
    font-size: 1rem;
    background-color: #4c6ef5;
    color: #fff;
    border-radius: 4px;
    text-decoration: none;
    transition: background-color 0.3s;
  }
  
  .not-found-link:hover {
    background-color: #3b58d4;
  }