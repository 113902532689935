.announcement-banner {
    top: 0;
    left: 0;
    margin-bottom: 5px;
    width: 100%;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    z-index: 9999;
    opacity: 1;
    transition: opacity 0.2s ease;
  }
  
  .announcement-banner.fade-out {
    opacity: 0;
  }

  /* Ensure text is readable and scaled properly */
  .announcement-message {
    font-size: 1rem;
    text-align: center;
    flex-grow: 1;
}
  
  /* A simple, accessible close button */
  .announcement-close {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    margin-left: 1rem;
}
  
  /* Responsive: On mobile, increase text size slightly and ensure spacing */
  @media (max-width: 600px) {
    .announcement-banner {
      padding: 0.75rem 1rem;
    }
  
    .announcement-message {
      font-size: 1.1rem;
    }
  
    .announcement-close {
      font-size: 2rem;
    }
}