body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; 
}

h1, h2, h3, p, a {
  font-size: clamp(1rem, 2.5vw, 1.5rem); /* Responsive font size */
}

button:hover {
  cursor: pointer;
}

.App {
  text-align: center;
  min-height: 100vh;
}

.App-header { 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  margin: 0 auto; 
}

h1 {
  margin: 0 0 20px 0;
}

p {
  font-family: 'Lato', sans-serif;
  font-size: clamp(1rem, 2.5vw, 2rem); /* Responsive font size */
  line-height: 1.6; /* Improves readability */
}
