/* Overall container styling */
.story-container {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    padding: 20px;
    margin: 0 auto;
    margin-top: 50px;
    max-width: 1200px;
    color: #2e2e2e;
  }
  
  /* Header section */
  .story-header {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .story-header h1 {
    font-size: 3rem;
    color: #4c6ef5;
    margin-bottom: 10px;
  }
  
  .story-header p {
    font-size: 1.25rem;
    color: #666;
  }
  
  /* Section layout */
  .story-section {
    display: flex;
    align-items: center;
  }
  
  .story-section.reverse {
    flex-direction: row-reverse;
  }
  
  .text-block {
    flex: 1;
    padding: 20px;
  }
  
  .text-block h2 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #4c6ef5;
  }
  
  .text-block p {
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .image-block {
    flex: 1;
    padding: 20px;
  }
  
  .image-block img {
    width: 50%;
    border-radius: 8px;
  }
  
  /* Call to Action */
  .call-to-action {
    text-align: center;
    margin: 60px 0;
  }
  
  .call-to-action h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #4c6ef5;
  }
  
  .call-to-action button {
    padding: 15px 30px;
    font-size: 1rem;
    color: white;
    background-color: #4c6ef5;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .call-to-action button:hover {
    background-color: #3b58d4;
  }
  
  /* Responsive design */
  @media (max-width: 768px) {
    .story-section {
      flex-direction: column;
    }
  
    .story-section.reverse {
      flex-direction: column;
    }
  
    .text-block, .image-block {
      padding: 10px;
    }
  }