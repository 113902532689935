.faq-container {
    max-width: 800px;
    margin: 50px auto;
    padding: 0 20px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
  }
  
  .faq-title {
    text-align: center;
    margin-top: 50px;
    margin-bottom: 30px;
    font-size: 2.5em;
    color: #333;
  }
  
  .faq-list {
    border-top: 1px solid #ccc;
  }
  
  .faq-item {
    border-bottom: 1px solid #ccc;
    transition: background 0.3s ease;
  }
  
  .faq-item:hover {
    background: #f9f9f9;
    cursor: pointer;
  }
  
  .faq-question {
    position: relative;
    padding: 20px;
    font-size: 1em;
    color: #555;
  }
  
  .faq-icon {
    position: absolute;
    right: 0;
    top: 20px;
    font-size: 1em;
    color: #888;
    transition: transform 0.3s ease;
  }
  
  .faq-item.active .faq-icon {
    transform: rotate(180deg);
  }
  
  .faq-answer {
    max-height: 0;
    overflow: hidden;
    padding: 0 20px;
    color: #666;
    transition: max-height 0.5s ease;
  }
  
  .faq-item.active .faq-answer {
    max-height: 500px;
    padding: 20px;
  }