.header {
  
  background-color: white;
}

.header:hover {
  cursor: pointer;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-title {
  margin: 0;
  color: #333;
  text-align: left;
}

.divider-header {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
}

.divider-header.visible {
  opacity: 1;
}

.divider-header .ms-Divider {
  width: 100%;
  opacity: 0.5;
}

.bookicon {
  margin-right: 7px;
  height: 3rem;
}

.order-now {
  color: black;
  background-color: #ffd200;
  border-radius: 20px;
  padding: 17px;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
}

.order-now:hover {
  background-color: #ecc102;
  cursor: pointer;
}

.language-selector {
  display: flex;
  align-items: center;
}

.language-selector select {
  padding: 5px;
  font-size: 16px;
}

.language-selector option {
  display: flex;
  align-items: center;
}

.language-selector option>svg {
  margin-right: 8px;
}

.changelanguage:hover {
  cursor: pointer;
}

.language-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  max-width: 300px;
  width: 80%;
  animation: flyInFromTop 0.5s ease-out;
}

.modal-content h3 {
  margin-bottom: 1em;
}

.modal-content-button {
  display: block;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #fff;
  color: #000;
  border: 1px solid #cccccc5a;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
  line-height: 26px;
  padding: 0;
  z-index: 1;
}

.language-button {
  background-color: white;
  border: 1px solid grey;
  border-radius: 10px;
  transition: background-color 0.3s ease, border 0.3s ease, box-shadow 0.3s ease;
}

.language-button:hover {
  background-color: rgb(240, 240, 240);
  /* Slightly darker shade */
  border: 1px solid rgb(200, 200, 200);
  /* Adjust border to match */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  /* Subtle shadow */
}

/* General Styles (Desktop) */
.header-container {
  padding: 20px 50px;
}

.icon-home {
  font-size: 4rem;
}

/* Medium Screens (Tablets, small laptops) */
@media (max-width: 1024px) {
  
  .header-container {
    padding: 15px 30px;
  }

  .icon-home {
    font-size: 3.5rem;
  }
}

/* Small Screens (Mobile) */
@media (max-width: 768px) {
  .header-container {
    padding: 10px 15px;
  }

  .icon-home {
    font-size: 3rem;
  }

  .dropzone {
    padding: 10px;
    font-size: 0.9rem;
  }

  .home-header h1 {
    font-size: 2.5rem;
  }

  .subtitle-text,
  .howitworks {
    font-size: 1.5rem;
  }

  .email-signup input,
  .email-signup button {
    width: 90%;
  }
}

/* Extra Small Screens (smaller mobiles) */
@media (max-width: 480px) {

  .header-container {
    padding: 15px 10px;
  }

  .icon-home {
    font-size: 2.5rem;
  }

  .dropzone {
    padding: 5px;
    font-size: 0.8rem;
  }

  .home-header h1 {
    font-size: 2rem;
  }

  .subtitle-text,
  .howitworks {
    font-size: 1.2rem;
  }
}